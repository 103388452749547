<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Finance<span class="vs-breadcrum--separator">/</span></li>
      <li>
        <router-link :to="{name: 'FinancialReports'}">Gotovina</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">

      <li aria-current="page" class="active">
        <span v-if="$route.params.financialReportId">Urejanje zapisa</span>
        <span v-if="!$route.params.financialReportId">Dodajanje zapisa</span>
      </li>
    </vs-breadcrumb>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">

        <div class="grid grid-cols-1 mb-10">
          <div>
            <h1>
              <span v-if="$route.params.financialReportId">Urejanje zapisa</span>
              <span v-if="!$route.params.financialReportId">Dodajanje zapisa</span>
            </h1>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <div>
            <label class="vs-input--label">Datum</label>
            <datepicker placeholder="Datum" :language="slSI" :format="customDateFormatter"
                        :monday-first="true"
                        v-model="financialReportData.date"></datepicker>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5" v-if="$globalFunctions.isAdmin()">
          <div>
            <label class="vs-input--label">Zapis za enoto</label>
            <v-select id="unit" :options="unitsData" class="" @input="onUnitChange" style="min-width: 300px"
                      v-model="unitId" placeholder="Zapis za enoto"
                      :reduce="item => item.id"
                      v-if="unitsData"
                      :clearable="false"
                      label="name">

              <template v-slot:option="option">
                <vs-list-item
                  :title="option.name"
                  :subtitle="option.coveredMunicipalities">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:selected-option="option">
                <vs-list-item
                  :title="option.name"
                  :subtitle="option.coveredMunicipalities">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
              </template>
            </v-select>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <label class="vs-input--label">Vrsta spremembe stanja</label>
            <v-select id="transacrionType" :options="directionsData" class="" style="min-width: 300px"
                      v-model="financialReportData.direction" placeholder="Vrsta spremembe stanja"
                      :reduce="item => item.id"
                      :clearable="false"
                      label="name">

              <template v-slot:option="option">
                <vs-list-item
                  :title="option.name + ' '">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.id && option.id == 'WITHDRAW' ? '-' : '+'"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:selected-option="option">
                <vs-list-item
                  :title="option.name">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.id && option.id == 'WITHDRAW' ? '-' : '+'"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
              </template>
            </v-select>
          </div>
        </div>
        <div class="flex">
          <div class="w-full" v-if="$v.financialReportData.direction.$dirty">
            <vs-alert :active="!$v.financialReportData.direction.required" :color="'danger'" class="mt-2">
              Polje "Vrsta spremembe stanja" ne sme biti prazno.
            </vs-alert>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <vs-input class="w-full" label="Višina zneska v eur" type="text"
                      v-model="financialReportData.amount"/>
          </div>
        </div>
        <div class="flex">
          <div class="w-full" v-if="$v.financialReportData.amount.$dirty">
            <vs-alert :active="!$v.financialReportData.amount.required" :color="'danger'" class="mt-2">
              Polje "Višina zneska v eur" ne sme biti prazno.
            </vs-alert>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <label class="vs-input--label">Opis</label>
            <vs-textarea class="w-full mb-0"
                         v-model="financialReportData.description"/>
          </div>
        </div>
        <div class="flex">
          <div class="w-full" v-if="$v.financialReportData.description.$dirty">
            <vs-alert :active="!$v.financialReportData.description.required" :color="'danger'" class="mt-2">
              Polje "Opis" ne sme biti prazno.
            </vs-alert>
          </div>
        </div>


        <div class="flex mt-10 mb-20">
          <div class="w-1/3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-2/3">
            <div class="flex">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="financialReportData.id">
                <vs-button size="large" color="danger" @click="beforeDeleteFinancialReport" icon="delete">Izbriši
                </vs-button>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" @click="saveFinancialReport()" v-if="!financialReportData.id">Shrani</vs-button>
                <vs-button size="large" color="success" icon="save" @click="updateFinancialReport()" v-if="financialReportData.id">Shrani spremembe</vs-button>
              </vs-col>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import PrivateDonationsTable from "../../components/Sopotniki/donations/PrivateDonationsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import {minLength, required} from "vuelidate/lib/validators";


export default {
  name: 'FinancialReportEdit',

  components: {
    'v-select': vSelect,
    Datepicker,
    PrivateDonationsTable
  },

  data() {
    return {
      unitsData: null,
      unitId: this.$globalFunctions.getCurrentUnit().id,
      financialReportData: {
        unitId: null,
        direction: null,
        date: new Date(),
        amount: null,
        description: null,
      },
      slSI: slSI,
      directionsData: [{
        id: 'WITHDRAW',
        name: 'odliv (-)'
      },
        {
          id: 'DEPOSIT',
          name: 'priliv (+)'
        }],
    }
  },

  validations: {
    financialReportData: {
      direction: {
        required
      },
      amount: {
        required
      },
      description: {
        required
      }
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      if (_this.$route.params.financialReportId) {
        _this.getFinancialReport();
      }

      // values passed from add donation form
      console.log("route", _this.$route)
      if(_this.$route.params && _this.$route.params.donationData) {
        _this.financialReportData.unitId = _this.$route.params.donationData.unitId;
        _this.financialReportData.direction = 'DEPOSIT';
        _this.financialReportData.date = _this.$route.params.donationData.donationDate;
        _this.financialReportData.amount = _this.$route.params.donationData.donationAmount;
        _this.financialReportData.description = 'Donacija osebe: ' + _this.$route.params.donationData.donorName;
      }

      _this.$vs.loading.close();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    next();
  },

  methods: {
    onUnitChange() {
      const _this = this;

      _this.getFinancialReports(_this.unitId);
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {
          _this.unitsData = _.filter(res.data.data, {disabled: 0});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getFinancialReport() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting/' + _this.$route.params.financialReportId)
        .then((res) => {
          _this.financialReportData = res.data.data;
          _this.unitId = res.data.data.unit_id;
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    beforeDeleteFinancialReport() {
      const _this = this;

      Vue.$confirm({
        title: 'Ali ste prepričani, da želite izbrisati ta vnos?',
        message: 'Po potrditvi bo vnos trajno izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.$vs.loading();
            _this.deleteFinancialReport();
          }
        }
      });


    },

    deleteFinancialReport() {
      const _this = this;

      Vue.prototype.$http.delete(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting/' + _this.$route.params.financialReportId)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'FinancialReports'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    saveFinancialReport() {
      const _this = this;

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      _this.$vs.loading();

      let financialReportData = {
        "unitId": _this.unitId,
        "direction": _this.financialReportData.direction,
        "date": _this.moment(_this.financialReportData.date).valueOf(),
        "amount": parseFloat(_this.financialReportData.amount.toString().replace(',','.')),
        "description": _this.financialReportData.description,
      };

      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting', financialReportData)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'FinancialReports'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    updateFinancialReport() {
      const _this = this;

      this.$v.$touch();
      this.$nextTick(() => this.focusFirstStatus());
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        return;
      }

      _this.$vs.loading();

      let financialReportData = {
        "unitId": _this.unitId,
        "direction": _this.financialReportData.direction,
        "date": _this.moment(_this.financialReportData.date).valueOf(),
        "amount": parseFloat(_this.financialReportData.amount.toString().replace(',','.')),
        "description": _this.financialReportData.description,
      };

      Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'financial-reporting/' + _this.financialReportData.id, financialReportData)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$router.push({name: 'FinancialReports'});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    }
  }
}
</script>

